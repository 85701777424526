.program-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(35, 35, 35);
    color: #ffffff;
    padding: 2rem;
}

.form-group h5{
    text-align: center;
}

.options-container{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-size: 1.5rem;
    padding: 1rem;
    width: 100%;
    gap: 1rem;
}
#custom-input {
    width: 30%;
    display: inline;
    margin-left: 1rem;
}
.start-button-container {
    text-align: center;
}
#start-button {
    width: 30%;
    scale: 1.2;
    margin-top: 1rem;
}
#custom-input-validator {
    display: none;
    background-color: rgb(220, 53, 69);
    color: white;
    margin-top: 2rem;
    text-align: center;
    padding: 1rem;
    border-radius: 10px;
}