.footer {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    height: 30vh;
    width: 100%;
    margin-top: 1rem;
    color: white;
    scale: 0.7;

  }
  .contact-me {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
  }
  .contact-me-info p {
    margin-top: 1rem;
  }
  .contact-info {
    display: flex;
    align-items: center;
  }
  .contact-info div p {
    margin: 1rem;
  }
  
  /*Extra small devices (phones, 600px and down)*/
  @media only screen and (max-width: 600px) {
    .contact-info {
      font-size: 0.5rem;
      justify-content: space-around;
    }
    .contact-info img {
      height: 30px;
      width: 30px;
    }
  }

  