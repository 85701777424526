#problems-container {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

#problems-container h1 {
    text-align: center;
}

#answer-input-div {
    width: 10%;
    margin: auto;
    margin-top: 2rem;
}

#answer-button {
    scale: 1.5;
    margin-top: 2rem;
    
}

#end-button {
    scale: 1.5;
    margin-top: 3rem;
}

#next-button {
    display: none;
    scale: 1.5;
    margin-top: 2rem;
}

#home-button {
    display:none;
    scale: 1.5;
}

#answer-input-validator {
    display: none;
    background-color: rgb(220, 53, 69);
    color: white;
    margin-top: 2rem;
    width: 40%;
    text-align: center;
    padding: 1rem;
    border-radius: 10px;
}

@media only screen and (max-width: 767px) {
    #answer-input-div {
        width: 30%;
    }
  }
  @media only screen and (max-width: 768px) {
    #answer-input-div {
        width: 20%;
    }
  }