@import 'bootstrap/dist/css/bootstrap.min.css';

* {
  margin: 0;
  padding:0;
  box-sizing: border-box;
}

body {
  background-color: #3f3e3e !important;
}

.app-container h1 {
  text-align: center;
  margin-top: 1rem;
  color: white;
  
}
